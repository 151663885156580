/*////////////////////////////////////////////////////////////   SLIDER   /////////////*/
.about_slider{
	.slide{
		padding: 20px;
		transform: scale(.95);
		opacity: .5;
		transition: all .3s ease-out;
		cursor: pointer;
		@include bp(md){
			padding: 10px;
		}
		@include bp(sm){
			padding: 5px;
		}
		.title, .content{
			opacity: 0;
			pointer-events: none;
		}
	}
	.slick-center{
		.slide{
			transform: scale(1);
			opacity: 1;
			.title, .content{
				opacity: 1;
				pointer-events: all;
			}
		}
	}
	img{
		min-width: 100%;
	}
}
.about_text_slider{
	text-align: center;
	margin: 0 0 50px 0;
	.slide{
		padding: 0 20%;
		@include bp(sm){
			padding: 0 20px;
		}
	}
	.slick-dots{
		display: flex !important;
		justify-content: center;
		padding: 0;
		li{
			list-style-type: none;
		}
		button{
			width: 8px;
			height: 8px;
			background: #d1d1d1;
			border-radius: 30px;
			margin: 5px;
			border: 0;
			padding: 0;
			text-indent: -9999px;
		}
		.slick-active{
			button{
				background: $primary;
			}
		}
	}
}

/*////////////////////////////////////////////////////////////   CTA PRESTATION   /////////////*/

.cta_service{
	padding: 85px;
	border-top: 1px solid #e4e6ea;
	@include bp(lg){
		padding: 60px;
	}
	@include bp(sm){
		padding: 30px;
	}
	.lead{
		font-size: 1.5rem;
	}
	&_list{
		background: url(../images/cta_bg.jpg) no-repeat;
		background-size: cover;
		color: white;
		margin: 0;
		@include bp(lg){
			background: url(../images/cta_bg_mobile.jpg) no-repeat;
		}
	}
	&_item{
		padding: 45px;
		border-right: 1px solid rgba(255,255,255,.4);
		display: flex;
		align-items: center;
		transition: all .2s ease-out;
		color: white;
		@include bp(lg){
			border-right: 0;
			border-bottom: 1px solid rgba(255,255,255,.4);
		}
		@include bp(sm){
			padding: 30px;
		}
		i{
			font-size: 3rem;
			@include bp(sm){
				font-size: 2.5rem;
			}
		}
		.content{
			margin-left: 30px;
			font-size: 1.4rem;
			font-weight: 500;
			@include bp(sm){
				font-size: 1.1rem;
			}
			.title{
				display: block;
			}
			.link{
				display: inline-block;
				text-transform: uppercase;
				font-size: .8rem;
				color: rgba(255,255,255,.5);
				transition: all .3s ease-out;
				&:after{
					content:'';
					display: block;
					background: white;
					width: 100%;
					height: 2px;
					opacity: .3;
					margin-top: 5px;
					transition: all .3s ease-out;
				}
			}
		}
		&:hover{
			background: rgba(0,0,0,.3);
			color: white;
			.link{
				color: white;
				&:after{
					opacity: 1;
				}
			}
		}
	}
}