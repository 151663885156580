/*////////////////////////////////////////////////////////////   GLOBAL   /////////////*/
.maincontent{
	padding-bottom: 70px;
	@include bp(lg){
		padding-bottom: 0px;
	}
}

.maincontent-item{
	margin-bottom: 60px;
	&:nth-child(even){
		flex-direction: row-reverse;
		figure{
			text-align: right;
			@include bp(lg){
				text-align: center;
			}
		}
	}
	figure{
		@include bp(lg){
			text-align: center;
		}
	}
	.step{
		color: #aaacae;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: .75rem;
	}
}