/*////////////////////////////////////////////////////////////   GLOBAL   /////////////*/
.contact{
	margin: 0;
}


/*////////////////////////////////////////////////////////////   COORDONEE   /////////////*/
.coordonee{
	background: url(../images/bg-prestation.jpg) no-repeat right top;
	padding: 80px 85px;
	@include bp(xl){
		padding: 60px 86px;
	}
	@include bp(lg){
		order: 2;
		padding: 60px 85px 0;
	}
	@include bp(md){
		padding: 60px 55px 0;
	}
	@include bp(sm){
		padding: 40px 25px 0;
	}
	.maintitle{
		margin-bottom: 20px;
		&:before{
			display: none;
		}
	}
	article{
		margin-bottom: 80px;
		@include bp(sm){
			margin-bottom: 50px;
		}
		&:first-child{
			li{
				list-style-type: none;
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}
			li > i{
				background: white;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 60px;
				color: $primary;
				margin-right: 10px;
			}
		}
		ul{
			padding: 0;
		}
	}
	.reseau{
		background: $primary;
		color: white;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 60px;
	}
}

/*////////////////////////////////////////////////////////////   FORM   /////////////*/

.contact-form{
	padding: 90px;
	@include bp(xl){
		padding: 50px;
	}
	@include bp(lg){
		padding: 0 45px 50px 45px;
	}
	@include bp(sm){
		padding: 0 15px 30px 15px;
	}
	button{
		margin: 15px;
	}
}

.form-control{
	border-radius: 0;
	padding: 10px 20px;
	height: auto;
	border-color: #e4e6ea;
	&:focus{
		box-shadow: none;
		border-color: #e4e6ea;
		border-left: 4px solid $primary;
	}
}

.form-group{
	label{
		color: $primary;
		font-weight: 500;
	}
}

.custom-control{
	color: #959b9e;
	font-size: .9rem;
	padding: 0 0 0 40px;
}