/*//////////////////////////////////////////////////////////   GLOBAL SETTINGS  /////////////*/
.galerie-item{
	position: relative;
	cursor: pointer;
	margin-bottom: 30px;
	&:hover{
		.title{
			opacity: 1;
		}
	}
	figure{
		margin: 0;
	}
	img{
		min-width: 100%;
	}
	.title{
		position: absolute;
		bottom: 0;
		color: white;
		width: calc(100% - 30px);
		opacity: 0;
		transition: all .4s ease-out;
		@include bp(sm){
			opacity: 1;
		}
		h3{
			margin-bottom: 0;
			font-size: 1.05rem;
			position: relative;
			z-index: 2;
			padding: 22px 70px 22px 30px;
			text-align: left;
			@include bp(lg){
				font-size: .9rem;
			}
			&:after{
				content:'+';
				display: block;
				padding: 4px 9px;
				border: 1px solid rgba(255,255,255,.4);
				border-radius: 60px;
				position: absolute;
				width: 30px;
				height: 30px;
				right: 20px;
				bottom: 20px;
			}
		}
		&:after{
			content: '';
			width: 100%;
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 180px;
			left: 0;
			bottom: 0;
			background: linear-gradient(
				to top,
				hsl(0, 0%, 1%, 0.741) 0%,
				hsla(0, 0%, 1%, 0.741) 8.1%,
				hsla(0, 0%, 1%, 0.741) 15.5%,
				hsla(0, 0%, 1%, 0.741) 22.5%,
				hsla(0, 0%, 1%, 0.741) 29%,
				hsla(0, 0%, 1%, 0.741) 35.3%,
				hsla(0, 0%, 1%, 0.648) 41.2%,
				hsla(0, 0%, 1%, 0.55) 47.1%,
				hsla(0, 0%, 1%, 0.45) 52.9%,
				hsla(0, 0%, 1%, 0.352) 58.8%,
				hsla(0, 0%, 1%, 0.259) 64.7%,
				hsla(0, 0%, 1%, 0.175) 71%,
				hsla(0, 0%, 1%, 0.104) 77.5%,
				hsla(0, 0%, 1%, 0.049) 84.5%,
				hsla(0, 0%, 1%, 0.013) 91.9%,
				hsla(0, 0%, 1%, 0) 100%
			);
		}
	}
}

/*//////////////////////////////////////////////////////////   TRI  /////////////*/

.tri{
	text-align: center;
	.divider{
		position: relative;
		&:after{
			content:'';
			background: #e4e6ea;
			width: 100%;
			height: 1px;
			display: block;
			position: absolute;
			top: 50%;
			z-index: -1;
		}
		span{
			background: white;
			padding: 5px 50px;
		}
	}
	&-list{
		margin: 40px 0;
	}
	&-item{
		background: none;
		border: 1px solid #e4e6ea;
		color: $primary;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: .75rem;
		font-weight: 600;
		padding: 10px 30px;
		transition: all .2s ease-out;
		&:hover{
			background: rgba(228,230,234,.5);
		}
		&.active{
			background: $primary;
			border: none;
			color: white;
			&:hover{
				background: darken($primary,10%);
			}
		}
	}
	.list-inline-item:not(:last-child), .list-inline-item{
		margin: 0 30px;
		@include bp(lg){
			margin: 0 30px 10px;
		}
		@include bp(md){
			margin: 0 15px 10px;
		}
	}
}


/*//////////////////////////////////////////////////////////   PAGINATION  /////////////*/

.pagination{
	ul{
		display: flex;
		justify-content: center;
		margin: auto;
		padding: 0;
	}
	li{
		list-style-type: none;
		border: 1px solid #e4e6ea;
		font-size: .75rem;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: -1px;
		cursor: pointer;
		transition: all .2s ease-out;
		&:hover{
			background: #f1f3f6;
		}
		&:first-child{
			margin-left:0;
		}
		button{
			color: #919394;
			background: none;
			border: 0;
			width: 100%;
			height: 100%;
		}
		.active{
			background: #f1f3f6;
			color: $primary;
			font-weight: 500;
		}
	}
}

/*//////////////////////////////////////////////////////////   MODAL  /////////////*/


/* Lightbox background */
.lightbox {
  display:none;
  background: rgba(0,0,0,0.8);
  position:fixed;
  top:0px;
  left:0px;
  min-width:100%;
  min-height:100%;
  z-index:1000;
}
/* Lightbox panel with some content */
.lightbox-panel {
  display:none;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  max-width: 800px;
  z-index:1001;
  .slick-arrow{
  	background: none;
  	border: none;
  	color: white;
  	position: absolute;
  	top: 50%;
  	transition: all .2s ease-out;
  	&:hover{
  		background: rgba(255,255,255,.2);
  	}
  }
  .slick-prev{
  	left: -50px;
  }
  .slick-next{
  	right: -50px;
  }
}