/*////////////////////////////////////////////////////////////   GLOBAL   /////////////*/
body{
	font-family: WorkSans;
	color: $font-text-color;
	
	@include bp(md){
		font-size: .9rem;
	}
	ul{
		margin-bottom: 0;
		padding: 0;
		li{
			list-style-type: none;
		}
	}
	img{
		max-width: 100%;
	}
	a{
		&:hover{
			text-decoration: none;
		}
	}
}
.btn{
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 500;
}

.maintitle{
	color: #4e5255;
	font-size: 1.3rem;
	&:before{
		content: $icon-feuille;
		display: block;
		font-family: icomoon;
		font-size: 3.5rem;
		color: #b4bac7;
	}
}

.lead{
	color: $primary;
	font-weight: 600;
	font-size: 1.8rem;
	@include bp(lg){
		font-size: 1.5rem;
	}
	@include bp(sm){
		font-size: 1.3rem;
	}
}

.bloc-cms{
	padding: 50px 15px;
	.content{
		width: 80%;
		margin: auto;
		@include bp(md){
			width: 100%;
		}
	}
	.retrait{
		padding-left: 30px;
		border-left: 1px solid #dedede;
	}
	.maintitle{
		text-align: center;
	}
}


/*////////////////////////////////////////////////////////////   HEADER   /////////////*/

.topbar{
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 70px 85px;
	z-index: 99;
	top: 0;
	transition: all .2s ease-out;
	@include bp(lg){
		padding: 50px 60px;
	}
	@include bp(sm){
		padding: 30px;
	}
	.menu{
		display: flex;
		text-transform: uppercase;
		padding: 0;
		transition: all .3s ease-out;
		@include bp(lg){
			background: $primary;
			height: 100vh;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			opacity: 0;
			pointer-events: none;
		}
		a{
			color: white;
			letter-spacing: 1px;
			font-size: 0.9rem;
			position: relative;
			&:after{
				content: '';
				display: block;
				background: white;
				position: absolute;
				width: 0;
				height: 1px;
				margin-top: 3px;
				border: 0;
				margin-left: 0;
				opacity: .5;
				transition: all .2s ease-out;
			}
			&:hover{
				&:after{
					width: 100%;
				}
			}
		}
		li{
			list-style-type: none;
			margin-left: 85px;
			@include bp(xl){
				margin-left: 50px;
			}
		}
	}
	.animate{
		.menu{
			pointer-events: all;
			opacity: 1;
			li{
				margin: 0 0 20px 0;
			}
		}
	}
	/*///////////// setup for dropdown on service */
	.dropdown-menu{
		border: none;
		border-radius: 0;
		box-shadow: 0px 0px 40px -10px rgba(80,88,130,.35);
		text-transform: none;
		a{ 
			color: #4e5255;
			letter-spacing: 0px;
			&:after{
				display: none;
			}
		}
		.dropdown-item:active{
			color: white;
		}
	}

	/*///////////// new style when scrolled */
	&.scrolled{
		background: white;
		padding: 20px 85px;
		box-shadow: 0px 0px 40px -10px rgba(80,88,130,.35);
		@include bp(lg){
			padding: 20px 60px;
		}
		@include bp(sm){
			padding: 15px 30px;
		}
		.top_logo{
			@include bp(sm){
				max-height: 30px;
			}
		}
		.menu a{
			color: #4e5255;
			&:after{
				background: #4e5255;
				opacity: .3;
			}
		}
		.x, .y, .z {
			background: #4e5255;
		}
		.animate {
			.menu a{
				color: white;
			}
			.dropdown-menu a{
				color: #4e5255;
			}
		}

	}
	/*///////////// adjust padding and border for inner page */
	&.inner{
		padding: 45px 85px 27px;
		border-bottom: 1px solid rgba(255,255,255,.3);
		@include bp(lg){
			padding: 45px 60px 25px;
		}
		@include bp(sm){
			padding: 30px 30px 20px;
		}
		&.scrolled{
			padding: 20px 85px;
			@include bp(lg){
				padding: 20px 60px;
			}
			@include bp(sm){
				padding: 15px 30px;
			}
		}
	}
}

/* ///////////////////  Nav Burger  ////////////////////////*/

.burger-wrap{
    position: relative;
}
.burger {
    height: 17px; 
    width: 26px;
    cursor: pointer;    
    z-index: 1110;
    transition: all 0.2s ease-in-out;
    .x, .y, .z {
        height: 2px; width: 26px;
        position: relative;
        background: white;
        border-radius:2px;
        transition: all 200ms ease-out;
    }   
    .y{top:5px;}
    .z{top:10px;}
    &:hover, &.open{
        .x, .y, .z{
            background: white;
        }
    }
}
.rotate30{
    transform: rotate(45deg);
    transform-origin: 4px;
}
.rotateneg{
    transform: rotate(-45deg);
    transform-origin: 5px;
}


/*////////////////////////////////////////////////////////////   FOOTER   /////////////*/

.foot-info{
	padding-top: 180px;
	padding-bottom: 20px;
	text-align: center;
	background: url(../images/footer-bg.png) no-repeat right bottom;
	@include bp(sm){
		padding-top: 50px;
		background: url(../images/footer-bg.png) no-repeat left bottom;
	}
	.divider{
		height: 1px;
		background: #e4e6ea; 
	}
	.nav-footer{
		margin: 25px auto;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: .8rem;
		font-weight: 500;
		a{
			color: #969dad;
			&:hover{
				color: #4e5255;
			}
		}
		li{
			margin: 0 30px;
		}
		/*///////////// setup for dropdown on service */
		.dropdown-menu{
			border: none;
			border-radius: 0;
			box-shadow: 0px 0px 40px -10px rgba(80,88,130,.35);
			text-transform: none;
			a{ 
				color: #4e5255;
				letter-spacing: 0px;
				&:after{
					display: none;
				}
			}
			.dropdown-item:active{
				color: white;
			}
		}

	}
	.rs-list{
		display: flex;
		justify-content: center;
		margin: 20px auto 40px;
		@include bp(md){
			flex-direction: column;
		}
		li, a{
			list-style-type: none;
			display: flex;
			align-items: center;
			color: $font-text-color;
		}
		li{
			margin: 0 20px;
			@include bp(md){
				margin: 0 15px 15px;
				&:last-child{
					margin: 0 15px;
				}
			}
		}
		i{
			background: #faf3ea;
			border-radius: 50px;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.1rem;
			color: $primary;
			margin-right: 10px;
			transition: all .3s ease-out;
			@include bp(md){
				background: white;
				box-shadow: 0px 0px 15px 0 rgba(80,88,130,.2);
			}
		}
		li a i {
			&:hover{
				background: $primary;
				color: white;
			}
		}
	}
	.credit{
		color: #cecece;
		font-size: .75rem;
		a{
			color: #cecece;
			&:hover{
				color: $font-text-color;
			}
		}
	}
	&.inner{
		padding-top: 40px;
	}
	.dropdown-toggle{
		&:after{
			display:none;
		}
	}
}

/*////////////////////////////////////////////////////////////   HEADBANNER   /////////////*/

.headbanner{
	margin: 20px 20px 0 20px;
	background: black;
	color: white;
	background-size: cover;
	padding: 150px 50px 50px;
	text-align: center;
	position: relative;
	@include bp(sm){
		margin: 10px 10px 0 10px;
		padding: 130px 40px 50px;
	}
	&:after{
		content: '';
		position: absolute;
		background: white;
		width: 1px;
		height: 30px;
		left: 50%;
		bottom: 0;
		z-index: 4;
	}
	i{
		margin-right: 25px;
		@include bp(sm){
			display: block;
			margin: 0 0 10px 0;
		}
	}
	.title{
		@include bp(sm){
			font-size: 1.7rem;
		}
	}
}

.ariane{
	background: $primary;
	padding: 20px 65px;
	margin: 0 20px;
	color: rgba(255,255,255,.4);
	font-size: .8rem;
	@include bp(lg){
		padding: 20px 40px;
	}
	@include bp(sm){
		margin: 0 10px;
		padding: 15px 20px;
	}
	a{
		color: rgba(255,255,255,.4);
		
		&:hover{
			color: white;
		}
	}
	li{
		&:after{
			content: $icon-arrow-right;
			font-family: icomoon;
			font-size: .6rem;
			display: inline-block;
			color: white;
			margin: 0 8px 0 11px;
		}
		&:last-child{
			&:after{
				display:none;
			}
		}
	}
}

.intro{
	text-align: center;
	font-size: 1.1rem;
	padding: 50px 150px;
	color: #5a5a5a;
	@include bp(lg){
		padding: 50px 15px;
	}
	@include bp(md){
		font-size: 1rem;
	}

}