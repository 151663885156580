/*////////////////////////////////////////////////////////////   HEADER   /////////////*/

@keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpSD {
  animation-name: fadeInUpSD;
}

.home_slider{
	margin: 20px; 
	position: relative;
	color: white;
	@include bp(sm){
		margin: 10px;
	}
	.content_slider{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 0 170px;
		width: 100%;
		display: flex;
		align-items: center;
		z-index: 3;
		@include bp(xl){
			top: 55%;
		}
		@include bp(lg){
			padding: 0 90px;
		}
		@include bp(sm){
			padding: 0 50px 0 20px;
			top: 50%;
		}
		div{
			padding: 30px 0;
			width: 60%;
			@include bp(xl){
				width: 90%;
			}
			@include bp(sm){
				width: 100%;
			}
		}
	}
	/*///////////// setup animation on slide change */
	.slick-active .animate{
		animation-name: fadeInUpSD;
 		animation-duration: 1s;
	}
	.content-title{
		font-size: 2.7rem;
		@include bp(lg){
			font-size: 2rem;
		}
		@include bp(md){
			font-size: 1.5rem;
		}
	}
	.content{
		animation-delay: .25s;
		margin: 25px 0;
		@include bp(lg){
			margin: 15px 0 20px;
		}
	}
	.content-btn{
		animation-delay: .5s;
	}
	/*///////////// Setting img as background like */
	figure{
		min-width: 100%;
		max-height: calc(100vh - 40px);
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		@include bp(sm){
			max-height: none;
			height: calc(100vh - 20px);
		}
		img{
			min-width: 100%;
			@include bp(sm){
				min-height: 100%;
				max-width: none;
				min-width: auto;
			}
		}
		&:before{
			content: '';
			position: absolute;
			background: url(../images/head-deco.png) no-repeat;
			background-size: cover;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			mix-blend-mode: lighten;
		}

	}
	/*///////////// setup for scrolldown */
	.scrolldown{
		position: absolute;
		bottom: 70px;
		left: 50%;
		transform: translateX(-50%);
		border: 2px solid white;
		border-radius: 50px;
		width: 30px;
		height: 30px;
		color: white;
		padding: 5px 6px;
		font-size: .9rem;
		@include bp(lg){
			bottom: 50px;
		}
		&:after{
			content: "";
			display: block;
			height: 75px;
			width: 1px;
			background: white;
			margin: auto;
			@include bp(lg){
				height: 55px;
			}
		}
	}
	/*///////////// config slider */
	.slick-arrow{
		position: absolute;
		left: 65px;
		top: 50%;
		transform: translateY(-50%);
		@include bp(lg){
			font-size: .9rem;
			padding: 0;
			left: 30px;
		}
	}
	.slick-prev, .slick-next{
		border: 2px solid white;
		border-radius: 50px;
		background: none;
		width: 40px;
		height: 40px;
		color: white;
		@include bp(lg){
			width: 30px;
			height: 30px;
		}
	}
	.slick-prev{
		margin-top: -30px;
	}
	.slick-next{
		margin-top: 30px;
	}
	.slick-dots{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 65px;
		@include bp(lg){
			right: 30px;
		}
		@include bp(sm){
			right: 20px;
		}
		li{
			list-style-type: none;
			&:last-child{
				.slick-dots-content{
					border:0;
				}
			}
		}
		&-content{
			background: none;
			border: none;
			color: white;
			font-weight: 700;
			letter-spacing: 2px;
			margin: 0 0 20px;
			padding: 0 0 20px;
			font-size: .8rem;
			border-bottom: 1px solid rgba(255,255,255,.4);
		}
	}
}


/*////////////////////////////////////////////////////////////   ABOUT   /////////////*/


.about{
	position: relative;
	padding: 100px 0;
	margin: 40px 0;
	@include bp(sm){
		margin: 20px 0 0;
		padding: 50px 0 0;
	}
	/*///////////// layout */
	.container{
		margin: auto;
	}
	&:before{
		content: '';
		background: #f2e2ce;
		width: 70%;
		height: 100%;
		position: absolute;
		z-index: -1;
		left: 0;
		top:0;
		@include bp(md){
			width: 100%;
			height: 80%;
		}
	}
	article{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-right: 130px;
		@include bp(xl){
			padding-right: 100px;
		}
		@include bp(lg){
			padding-right: 0;
		}
	}
	aside{
		display: flex;
		justify-content: center;
		align-items: center;
		@include bp(md){
			padding: 30px 15px;
		}
	}
	/*///////////// text rules */
	.subtitle{
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 2px;
		font-size: .9rem;
		margin-bottom: 15px;
	}
	.content{
		font-size: .95rem;
		line-height: 2;
		margin: 5px 0 30px;
		@include bp(sm){
			line-height: initial;
		}
	}
}


/*////////////////////////////////////////////////////////////   SLIDER REA   /////////////*/


.realisation{
	text-align: center;
	padding: 10px 0 120px;
	@include bp(sm){
		padding: 10px 0 60px;
	}
	.galerie-item{
		padding: 20px;
		.title{
			bottom: 20px;
			width: calc(100% - 40px);
		}
	}
	&_slider{
		.slick-arrow{
			position: absolute;
			top: -50px;
			background: none;
			border: 1px solid #b5bbc8;
			border-radius: 60px;
			width: 40px;
			height: 40px;
		}
		.slick-prev{
			left: 20px;
		}
		.slick-next{
			right: 20px;
		}
	}
}



/*////////////////////////////////////////////////////////////   PRESTATION   /////////////*/

.prestation{
	background: url(../images/bg-prestation.jpg) no-repeat;
	background-size: cover;
	text-align: center;
	padding: 60px 0 150px;
	position: relative;
	@include bp(sm){
		padding: 40px 0 0;
	}
	&-list{
		padding-top: 50px;
		padding-bottom: 50px;
		@include bp(lg){
			.row{
				justify-content: center;
			}
		}
		@include bp(sm){
			padding-bottom: 20px;
		}
		ul{
			padding-left: 0;
		}
	}
	.maintitle{
		&:before{
			color: $primary;
		}
	}
	.icon{
		font-size: 4rem;
		color: $primary;
		border: 1px solid #d3b193;
		border-radius: 150px;
		padding: 40px;
		display: inline-block;
		margin-bottom: 30px;
		transition: all 1.2s;
	}
	.item{
		padding: 0 60px;
		list-style-type: none;
		@include bp(lg){
			margin-bottom: 20px;
		}
		@include bp(sm){
			margin-bottom: 40px;
		}
		.primary-link{
			text-transform: uppercase;
			font-size: .75rem;
			font-weight: 600;
			letter-spacing: 2px;
			position: relative;
			display: inline-block;
			&:after{
				content:'';
				display: block;
				background: $primary;
				width: 100%;
				height: 2px;
				opacity: .3;
				margin-top: 5px;
				transition: all .2s;
			}

		}
		&:hover{
			.icon{
				background: white;
			}
			.primary-link{
				&:after{
					opacity:1;
				}
			}
		}
		.content{
			color: #5a5a5a;
		}
	}
	.subtitle{
		color: white;
	}
}


.contact_bloc{
	background: white;
	position: absolute;
	padding: 60px 50px 40px;
	box-shadow: 0px 0px 40px -10px rgba(80,88,130,.35);
	top: 100%;
	left: 50%;
	transform: translate(-50%,-50%);
	color: #969dad;
	@include bp(sm){
		transform: none;
		position: relative;
		left: 0;
	}
	.maintitle{
		&:before{
			content: '';
			display: block;
			width: 1px;
			height: 40px;
			background: #eddeca;
			position: absolute;
			top: 0;
			left: 50%;
		}
	}
}

