//------------------------- FONTS

//import & setup

@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-Light.ttf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-Thin.ttf);
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: WorkSans;
  src: url(./../fonts/WorkSans-Black.ttf);
  font-weight: 900;
  font-style: normal;
}


//icomoon

$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "./../fonts/" !default;

$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-call: "\e904";
$icon-exterieur: "\e905";
$icon-facebook: "\e906";
$icon-feuille: "\e907";
$icon-fourniture: "\e908";
$icon-interieur: "\e909";
$icon-mail: "\e90a";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?c7shc9');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?c7shc9#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?c7shc9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?c7shc9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?c7shc9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-call {
  &:before {
    content: $icon-call; 
  }
}
.icon-exterieur {
  &:before {
    content: $icon-exterieur; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-feuille {
  &:before {
    content: $icon-feuille; 
  }
}
.icon-fourniture {
  &:before {
    content: $icon-fourniture; 
  }
}
.icon-interieur {
  &:before {
    content: $icon-interieur; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}


/*//////////////////////////////////////////////////   override & variables   /////////////*/
//------------------------- COLOR

$primary: #882e15;
$font-text-color: #292929;

//------------------------- TITLE



//------------------------- BTN

$btn-padding-y: .6rem;
$btn-padding-x: 2.1rem;
$btn-border-radius: 0;
$btn-font-size: .75rem;

//------------------------- FORM


//------------------------- BREAKPOINT

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1000px,
  xl: 1100px,
  xxl: 1440px,
);

@mixin bp($point) {


@if $point == sm{
  @media (max-width: 576px){ @content ; }
}

@if $point == md {
  @media (max-width: 768px){ @content ; }
}

@if $point == lg {
  @media (max-width: 992px){ @content ; }
}

@if $point == xl {
  @media (max-width: 1280px) { @content ; }
}

@else if $point == xxl {
  @media (max-width: 1440px){ @content ; }
}
}



//------------------------- EXTEND / MIXIN